/* .page-card .doc-card {
  border-bottom: lightgray 1px solid;
} */

.doc-card {
  color: rgb(48, 45, 45);
  background-color: #ffffff;
  padding: 20px;
  min-height: 15vh;
  margin: 10px;
  transition: 0.25s;
}

.doc-card a:hover {
  box-shadow: 0px 0px 1px rgba(128, 138, 157, 0.12),
    0px 0px 5px rgba(73, 95, 136, 0.14);
  color: lightslategray;
}

.navLinks li {
  display: inline;
  text-align: center;
}

.ending {
  min-height: 100px;
  height: 100%;
  width: 100%;
}

.ending-action {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
