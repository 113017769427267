@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::-webkit-scrollbar {
  width: 20px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.container {
    max-width: 1100px;
    margin: 0 auto;
    overflow: auto;
    padding: 0 40px;
}

.card {
    background-color: #fff;
    color: #333;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin: 10px;
}

.btn {
    display: inline-block;
    padding: 10px 30px;
    cursor: pointer;
    background: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 5px;
}

.btn-outline {
    background-color: transparent;
    border: 1px #fff solid;
}

.btn:hover {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
}


/* Backgrounds & colored buttons */

.bg-primary,
.btn-primary {
    background-color: var(--primary-color);
    color: #fff;
}

.bg-secondary,
.btn-secondary {
    background-color: var(--secondary-color);
    color: #fff;
}

.bg-dark,
.btn-dark {
    background-color: var(--dark-color);
    color: #fff;
}

.bg-light,
.btn-light {
    background-color: var(--light-color);
    color: #333;
}

.bg-primary a,
.btn-primary a,
.bg-secondary a,
.btn-secondary a,
.bg-dark a,
.btn-dark a {
    color: #fff;
}


/* Text colors */

.text-primary {
    color: var(--primary-color);
}

.text-secondary {
    color: var(--secondary-color);
}

.text-dark {
    color: var(--dark-color);
}

.text-light {
    color: var(--light-color);
}


/* Text sizes */

.lead {
    font-size: 20px;
}

.sm {
    font-size: 1rem;
}

.md {
    font-size: 2rem;
}

.lg {
    font-size: 3rem;
}

.xl {
    font-size: 4rem;
}

.text-center {
    text-align: center;
}


/* Alert */

.alert {
    background-color: var(--light-color);
    padding: 10px 20px;
    font-weight: bold;
    margin: 15px 0;
}

.alert i {
    margin-right: 10px;
}

.alert-success {
    background-color: var(--success-color);
    color: #fff;
}

.alert-error {
    background-color: var(--error-color);
    color: #fff;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.grid {
    display: grid;
    /* grid-template-columns: repeat(2, 1fr); */
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.grid-3 {
    grid-template-columns: repeat(3, 1fr);
}

.grid-2 {
    grid-template-columns: repeat(2, 1fr);
}

/* Margin */

.my-1 {
    margin: 1rem 0;
}

.my-2 {
    margin: 1.5rem 0;
}

.my-3 {
    margin: 2rem 0;
}

.my-4 {
    margin: 3rem 0;
}

.my-5 {
    margin: 4rem 0;
}

.m-1 {
    margin: 1rem;
}

.m-2 {
    margin: 1.5rem;
}

.m-3 {
    margin: 2rem;
}

.m-4 {
    margin: 3rem;
}

.m-5 {
    margin: 4rem;
}


/* Padding */

.py-1 {
    padding: 1rem 0;
}

.py-2 {
    padding: 1.5rem 0;
}

.py-3 {
    padding: 2rem 0;
}

.py-4 {
    padding: 3rem 0;
}

.py-5 {
    padding: 4rem 0;
}

.p-1 {
    padding: 1rem;
}

.p-2 {
    padding: 1.5rem;
}

.p-3 {
    padding: 2rem;
}

.p-4 {
    padding: 3rem;
}

.p-5 {
    padding: 4rem;
}
/* Navbar */
.navbar {
  background-color: var(--primary-color);
  color: #fff;
  height: 70px;  
}

.navbar ul {
  display: flex;
}

.navbar a {
  color: #fff;
  padding: 10px;
  margin: 0 5px;
}

.navbar .flex {
  justify-content: space-between;
}

.active {
  border-bottom: 2px #fff solid;
}

/* .page-card .doc-card {
  border-bottom: lightgray 1px solid;
} */

.doc-card {
  color: rgb(48, 45, 45);
  background-color: #ffffff;
  padding: 20px;
  min-height: 15vh;
  margin: 10px;
  transition: 0.25s;
}

.doc-card a:hover {
  box-shadow: 0px 0px 1px rgba(128, 138, 157, 0.12),
    0px 0px 5px rgba(73, 95, 136, 0.14);
  color: lightslategray;
}

.navLinks li {
  display: inline;
  text-align: center;
}

.ending {
  min-height: 100px;
  height: 100%;
  width: 100%;
}

.ending-action {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.showcase-form-card {
    padding: 5px;
}
.form-submit-control {
    display: flex;
    flex-direction: row;
}

#success-message {
    margin: auto;
    color: #26A96C;
    font-weight: bold;
    font-size: 14pt;
}

:root {
  --primary-color: #047aed;
  --secondary-color: #1c3fa8;
  --dark-color: #002240;
  --light-color: #f4f4f4;
  --success-color: #5cb85c;
  --error-color: #d9534f;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Lato", sans-serif;
  color: #333;
  line-height: 1.6;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h1,
h2 {
  font-weight: 300;
  line-height: 1.2;
  margin: 10px 0;
}

p {
  margin: 10px 0;
}

img {
  width: 100%;
}

code,
pre {
  background: #333;
  color: #fff;
  padding: 10px;
}

.hidden {
  visibility: hidden;
  height: 0;
}

/* Content */
.wrapper {
  min-height: 100vh;
}

/* Showcase */

.showcase {
  height: 400px;
  background-color: #047aed;
  background-color: var(--primary-color);
  color: #fff;
  position: relative;
}

.showcase h1 {
  font-size: 40px;
}

.showcase p {
  margin: 20px 0;
}

.showcase .grid {
  overflow: visible;
  grid-template-columns: 55% auto;
  grid-gap: 30px;
  gap: 30px;
}

.showcase-text {
  -webkit-animation: slideInFromLeft 1s ease-in;
          animation: slideInFromLeft 1s ease-in;
}

.showcase-form {
  position: relative;
  top: 60px;
  padding: 40px;
  z-index: 100;
  justify-self: flex-end;
  -webkit-animation: slideInFromRight 1s ease-in;
          animation: slideInFromRight 1s ease-in;
}

.showcase-form .form-control {
  margin: 30px 0;
}

.showcase-form input[type="text"],
.showcase-form input[type="email"] {
  border: 0;
  border-bottom: 1px solid #b4becb;
  width: 100%;
  padding: 3px;
  font-size: 16px;
}

.showcase-form input:focus {
  outline: none;
}

.showcase::before,
.showcase::after {
  content: "";
  position: absolute;
  height: 100px;
  bottom: -70px;
  right: 0;
  left: 0;
  background: #fff;
  transform: skewY(-3deg);
  -webkit-transform: skewY(-3deg);
  -moz-transform: skewY(-3deg);
  -ms-transform: skewY(-3deg);
}

/* Stats */

.stats {
  padding-top: 100px;
  -webkit-animation: slideInFromBottom 1s ease-in;
          animation: slideInFromBottom 1s ease-in;
}

.stats-heading {
  max-width: 500px;
  margin: auto;
}

.stats .grid h3 {
  font-size: 35px;
}

.stats .grid p {
  font-size: 20px;
  font-weight: bold;
}

/* Cli */

.cli .grid {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.cli .grid > *:first-child {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
}

/* Languages */

.languages .flex {
  flex-wrap: wrap;
}

.languages .card {
  text-align: center;
  margin: 18px 10px 40px;
  transition: -webkit-transform 0.2s ease-in;
  transition: transform 0.2s ease-in;
  transition: transform 0.2s ease-in, -webkit-transform 0.2s ease-in;
}

.languages .card h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.languages .card:hover {
  -webkit-transform: translateY(-15px);
          transform: translateY(-15px);
}

.page-header {
  text-align: center;
}

/* Features */

.features-head img,
.docs-head img {
  width: 200px;
  justify-self: flex-end;
}

.features-sub-head img {
  width: 300px;
  justify-self: flex-end;
}

.features-main .card > i {
  margin-right: 20px;
}

.features-main .grid {
  padding: 30px;
}

.features-main .grid > *:first-child {
  grid-column: 1 / span 3;
}

.features-main .grid > *:nth-child(2) {
  grid-column: 1 / span 2;
}

/* Docs */

.docs-main h3 {
  margin: 20px 0;
}

.docs-main .grid {
  grid-template-columns: 1fr 2fr;
  align-items: flex-start;
}

.docs-main nav li {
  font-size: 17px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px #ccc solid;
}

.docs-main a:hover {
  font-weight: bold;
}

/* Animations */

@-webkit-keyframes slideInFromLeft {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes slideInFromRight {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes slideInFromTop {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideInFromTop {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes slideInFromBottom {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideInFromBottom {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

/* Tablets and under */

@media (max-width: 768px) {
  .grid,
  .showcase .grid,
  .stats .grid,
  .cli .grid,
  .grid,
  .features-main .grid,
  .docs-main .grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  .showcase {
    height: auto;
  }
  .showcase-text {
    text-align: center;
    margin-top: 40px;
    -webkit-animation: slideInFromTop 1s ease-in;
            animation: slideInFromTop 1s ease-in;
  }
  .showcase-form {
    justify-self: center;
    margin: auto;
    -webkit-animation: slideInFromBottom 1s ease-in;
            animation: slideInFromBottom 1s ease-in;
  }
  .cli .grid > *:first-child {
    grid-column: 1;
    grid-row: 1;
  }
  .features-head,
  .features-sub-head,
  .docs-head {
    text-align: center;
  }
  .features-head img,
  .features-sub-head img,
  .docs-head img {
    justify-self: center;
  }
  .features-main .grid > *:first-child,
  .features-main .grid > *:nth-child(2) {
    grid-column: 1;
  }
}

/* Page */
.page-card {
  background-color: white;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 50px auto;
  min-width: 20vh;
  max-width: 90vh;
  /* align-items: center; */
  /* justify-content: center; */
}

/* Mobile */

@media (max-width: 500px) {
  .navbar {
    height: 110px;
  }
  .navbar .flex {
    flex-direction: column;
  }
  .navbar ul {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .showcase-form {
    width: 300px;
  }
}

