.showcase-form-card {
    padding: 5px;
}
.form-submit-control {
    display: flex;
    flex-direction: row;
}

#success-message {
    margin: auto;
    color: #26A96C;
    font-weight: bold;
    font-size: 14pt;
}
